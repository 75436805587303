/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useHtmlClassService } from "../../../layout";
import { DropdownMenu2, DropdownCustomToggler } from "../../dropdowns";
import { KTUtil } from "../../../_assets/js/components/util";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

export function MixedWidget15({ className }) {
  const uiService = useHtmlClassService();
  const [selectedDate, handleDateChange] = useState(new Date());
  const { bookingsBasedOnSpeciesType } = useSelector(
    (state) => ({bookingsBasedOnSpeciesType : state.dashboardData.bookingsBasedOnSpeciesType}),
    shallowEqual
  );
  
  // const categoriesData = Object.keys(bookingsBasedOnSpeciesType);
  // const seriesData = Object.keys(bookingsBasedOnSpeciesType);
  const layoutProps = useMemo(() => {
    return {
      // categoriesData: categoriesData,
      // seriesData:seriesData,
      colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
      colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_14_chart");
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, 'height'));
    const options = getChartOptions(layoutProps, height);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title font-weight-bolder ">Bookings Based On species Type</h3>
        <div className="card-toolbar">
          {/* <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
                as={DropdownCustomToggler}
            id="dropdown-toggle-top">
              <i className="ki ki-bold-more-hor" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu4 />
            </Dropdown.Menu>
          </Dropdown> */}

          <div className="form-group row" style={{ marginLeft: '30px' }}>
            <div className="col-lg-8">
              <label>Year and Month</label>
              <MuiPickersUtilsProvider utils={MomentUtils} >
                <DatePicker
                  fullWidth
                  variant="inline"
                  openTo="year"
                  views={["year", "month"]}
                  value={selectedDate}
                  onChange={handleDateChange}
                  autoOk={true}
                // minDate={moment().toDate()}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          {/* <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-transparent-dark btn-sm font-weight-bolder dropdown-toggle px-5"
              variant="transparent"
              id="dropdown-toggle-top">
              Month Wise Reports
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu2 />
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>
      {/* Body */}
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div id="kt_mixed_widget_14_chart" style={{ height: "200px" }}></div>
        </div>

      </div>
    </div>);
}

function getChartOptions(layoutProps, height) {
  console.log(layoutProps, "layoutProps")
  const options = {
    series: [
      {
        name: "basic",
        data: [15]
      }
    ],
    //series: layoutProps.seriesData,
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    // colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        "L vanamei"
      ],
      // categories: layoutProps.categoriesData,
      labels: {
        style: {
          // colors: colors,
          fontSize: '12px'
        }
      }
    }
  }
  return options;
}
