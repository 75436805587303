import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
// import {customersSlice} from "../app/modules/UserAccounts/_redux/customers/customersSlice";
import { dashboardSlice } from "../app/modules/DashboardAPI/dashboardSlice";
import { RolesSlice } from "../app/modules/RoleManagement/_redux/Roles/rolesSlice"
import { reportsSlice } from "../app/modules/Reports/_redux/reports/reportsSlice"
import { reports2Slice } from "../app/modules/Reports2/_redux/Reports/reportSlice"
import { masterCalendarsSlice } from "../app/modules/MasterCalendar/_redux/masterCalendar/masterCalendarsSlice"
import { employeesSlice } from "../app/modules/UserAccounts/_redux/Employees/employeesSlice"
import { larSlice } from "../app/modules/LabAnalysisReport/_redux/labAnalysisReports/labAnalysisReportsSlice"
import { apptransSlice } from "../app/modules/Approvals/_redux/Transactions/apptransSlice"
import { transactionsSlice } from "../app/modules/Transactions/_redux/Transactions/transactionsSlice"
import { bookingsSlice } from "../app/modules/Bookings/_redux/bookings/bookingsSlice"
import { hatcheriesSlice } from "../app/modules/Hatcheries/_redux/hatcheries/hatcheriesSlice"
import { apphatcheriesSlice } from "../app/modules/Approvals/_redux/Hatcheries/hatcheriesSlice"
import { bookingChangesSlice } from "../app/modules/Approvals/_redux/BookingChanges/BookingChangesSlice"
import { hatcheryChangesSlice } from "../app/modules/Approvals/_redux/HatcheryChanges/hatcheryChangesSlice"
import { clearanceCertificatesSlice } from "../app/modules/ClearanceCertificate/_redux/clearanceCertificate/clearanceCertificatesSlice"
import { paymentReceiptsSlice } from "../app/modules/PaymentReceipts/_redux/paymentReceipts/paymentReceiptsSlice"
import { suppliersSlice } from "../app/modules/Suppliers/_redux/suppliers/suppliersSlice"
import { hatcheryOwnersSlice } from "../app/modules/UserAccounts/_redux/HatcheryOwners/hatcheryOwnersSlice"
import { approvalDocumentsSlice } from "../app/modules/Approvals/_redux/Documents/documentsSlice"
//import {bookingsSlice} from "../app/modules/Approvals/_redux/Bookings/bookingsSlice"
// import {remarksSlice} from "../app/modules/LabAnalysisReport/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/LabAnalysisReport/_redux/specifications/specificationsSlice";
import { hatcherisSlice } from "../app/modules/Approvals/_redux/Hatcheries/hatcheriesSlice"
import { newbookingsSlice } from "../app/pages/cubicle-utilization/newbookingsSlice";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  // customers: customersSlice.reducer,
  //products: productsSlice.reducer,
  Roles: RolesSlice.reducer,
  dashboardData: dashboardSlice.reducer,
  reportsData: reportsSlice.reducer,
  reports2Data: reports2Slice.reducer,
  masterCalendarData: masterCalendarsSlice.reducer,
  employeesData: employeesSlice.reducer,
  larData: larSlice.reducer,
  apptransData: apptransSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer,
  transactions: transactionsSlice.reducer,
  bookings: bookingsSlice.reducer,
  newbookings: newbookingsSlice.reducer,
  hatcheries: hatcheriesSlice.reducer,
  apphatcheries: apphatcheriesSlice.reducer,
  bookingChanges: bookingChangesSlice.reducer,
  hatcheryChanges: hatcheryChangesSlice.reducer,
  clearanceCertificates: clearanceCertificatesSlice.reducer,
  paymentReceipts: paymentReceiptsSlice.reducer,
  suppliers: suppliersSlice.reducer,
  hatcheryOwners: hatcheryOwnersSlice.reducer,
  documents: approvalDocumentsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
