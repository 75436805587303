/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import { useHtmlClassService } from "../../../layout";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { DropdownMenu4, DropdownCustomToggler } from "../../dropdowns";
import { KTUtil } from "../../../_assets/js/components/util";

export function MixedWidget14({ className }) {
  const uiService = useHtmlClassService();
  const [selectedDate, handleDateChange] = useState(new Date());
  const { dashboardData } = useSelector(
    (state) => ({ dashboardData: state.dashboardData }),
    shallowEqual
  );
  
  function percentage(partialValue, totalValue) {
    if (partialValue !== 0 && totalValue !== 0) {
      const percent = (100 * partialValue) / totalValue;
      return percent.toFixed(0)
    }
    else {
      return 0;
    }


  }

  const layoutProps = useMemo(() => {
    return {
      bookingsPercentage: percentage(dashboardData.TotalBookingsVsOpenBookings, dashboardData.bookingsCount),
      colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
      colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);



  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_14_chart1");
    if (!element) {
      return;
    }
    if (dashboardData.TotalBookingsVsOpenBookings !== 0) {
      layoutProps.bookingsPercentage = percentage(dashboardData.TotalBookingsVsOpenBookings, dashboardData.bookingsCount);
    }

    const height = parseInt(KTUtil.css(element, 'height'));
    const options = getChartOptions(layoutProps, height);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, dashboardData.TotalBookingsVsOpenBookings]);

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title font-weight-bolder " style={{ marginLeft: '40px' }}>Bookings Closed</h3><hr></hr>
        <div className="card-toolbar">
          <div className="form-group row" style={{ marginLeft: '30px' }}>
            <div className="col-lg-8">
              <label>Year and Month</label>
              <MuiPickersUtilsProvider utils={MomentUtils} >
                <DatePicker
                  fullWidth
                  variant="inline"
                  openTo="year"
                  views={["year", "month"]}
                  value={selectedDate}
                  onChange={handleDateChange}
                  autoOk={true}
                // minDate={moment().toDate()}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
      </div>
      {/* Body */}
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div id="kt_mixed_widget_14_chart1" style={{ height: "200px" }}></div>
        </div>
        <div className="pt-5">
          <p className="text-center font-weight-normal font-size-lg pb-7">
            Total Bookings : {dashboardData.bookingsCount}
          </p>
          <p className="text-center font-weight-normal font-size-lg pb-7">
            Bookings Closed : {dashboardData.TotalBookingsVsOpenBookings}
          </p>
          {/* <a href="#" className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3">Generate Report</a> */}
        </div>
      </div>
    </div>
  );
}



function getChartOptions(layoutProps, height) {
  const options = {
    series: [layoutProps.bookingsPercentage],
    chart: {
      height: height,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "65%"
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
            fontWeight: "700",
          },
          value: {
            color: layoutProps.colorsGrayGray700,
            fontSize: "30px",
            fontWeight: "700",
            offsetY: 12,
            show: true
          },
        },
        track: {
          background: layoutProps.colorsThemeLightSuccess,
          strokeWidth: '100%'
        }
      }
    },
    colors: [layoutProps.colorsThemeBaseSuccess],
    stroke: {
      lineCap: "round",
    },
    labels: ["Progress"]
  };
  return options;
}
