import {createSlice} from "@reduxjs/toolkit";

const initialLabAnalysisReportsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  labAnalysisReportForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const larSlice = createSlice({
  name: "larData",
  initialState: initialLabAnalysisReportsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getLabAnalysisReportById
    labAnalysisReportFetched: (state, action) => {
      state.actionsLoading = false;
      state.labAnalysisReportForEdit = action.payload.labAnalysisReportForEdit;
      state.error = null;
    },
    // findLabAnalysisReports
    labAnalysisReportsFetched: (state, action) => {
      console.log(action.payload)
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createLabAnalysisReport
    labAnalysisReportCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.labAnalysisReport);
    },
    // updateLabAnalysisReport
    labAnalysisReportUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.labAnalysisReport.id) {
          return action.payload.labAnalysisReport;
        }
        return entity;
      });
    },
    // deleteLabAnalysisReport
    labAnalysisReportDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteLabAnalysisReports
    labAnalysisReportsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // labAnalysisReportsUpdateState
    labAnalysisReportsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
