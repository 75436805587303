import React, { useEffect, useState, useRef, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as actions from "./newbookingsActions";
import { Input, Select as SelectComp } from "../../../_websoc/_partials/controls";
import { Calendar as BigCalendar, momentLocalizer, Views } from "react-big-calendar";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import moment from 'moment';
import { array } from "prop-types";
import axios from 'axios';
import * as actionsDashboard from "../../../../src/app/modules/DashboardAPI/dashboardActions";
import swal from 'sweetalert';

// Validation schema

var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();
var d = today.getDate();


var getDaysInMonth = function (month, year) {
  // Here January is 1 based
  //Day 0 is the last day in the previous month
  return new Date(year, month, 0).getDate();
  // Here January is 0 based
  // return new Date(year, month+1, 0).getDate();
};




const localizer = momentLocalizer(moment);
const viewsCustom = { MONTH: "month" }
let allViews = Object.keys(Views).map(k => viewsCustom[k])

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

export function BookingCreateForm({
  booking,
  btnRef,
  saveBooking,
}) {

  const hatchery = useSelector((state) => state.dashboardData.hatcheryDetails, shallowEqual);
  const suppliers = useSelector((state) => state.bookings.suppliers, shallowEqual);
  const balanceBS = useSelector((state) => state.dashboardData.balanceBS, shallowEqual);
  const [yearForBS, setYearForBS] = useState(2021);
  const [speciesId, setSpeciesId] = useState(1);

  console.log(hatchery)
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(actionsDashboard.fetchAllBSPerYear(yearForBS));
    dispatch(actionsDashboard.fetchCubicles());

  }, [yearForBS, dispatch]);

  const [cubicals, setCubicals] = useState([])
  const [cubicalsAddLimit, setCubicalsAddLimit] = useState(0)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedCubical, setSelectedCubical] = useState([])
  const [enterTOtalNoOfImport, setEnterTOtalNoOfImport] = useState(null)
  const [EnterBiomass, setEnterBiomass] = useState(null)
  const [totalBiomass, setTotalBiomass] = useState(null)
  const [requiredCubicals, setRequiredCubicals] = useState(0)
  const [totalAmount, setTotalAmount] = useState(null)
  const [declarationDocument, setDeclarationDocument] = useState("")
  const [cubicalsAvailable, setCubicalsAvailable] = useState(0);
  const [cubicalsNotAvailable, setCubicalsNotAvailable] = useState(0);
  const [cubicalsBlocked, setCubicalsBlocked] = useState(0);


  const qtyChangedCubical = (value) => {
    // const objs = cubicals;
    // objs.push(value)
    setCubicals(value)
    // setTotalBSReleased(value)
  }
  // booking.selectedCubicalObj = selectedCubicalObj;
  const selectedEvent = (event) => {
    console.log(requiredCubicals, cubicalsAddLimit)
    setSelectedDate(event.start);
    if (requiredCubicals === 0 || EnterBiomass < 20) {
      console.log('Conditions not required in Dashboard it just utilization view')
    } else {
      if (event.color === "green") {

        if (selectedDate != null && event.start === selectedDate) {

          if (cubicalsAddLimit < requiredCubicals && cubicalsAddLimit < 2) {
            const b = selectedCubical
            b.push(event.scheduleId)
            setSelectedCubical(b);
            const a = cubicals;
            a.push(event)
            setCubicals(a)
            setCubicalsAddLimit(cubicalsAddLimit + 1)
          } else {
            console.log('Conditions not required in Dashboard it just utilization view')
          }

        } else {

          setCubicalsAddLimit(1)
          setSelectedCubical(cubicals);
          const a = [];
          const b = []
          a.push(event)
          setCubicals(a)
          b.push(event.scheduleId)
          setSelectedCubical(b);

        }
      } else {
        // swal("");
      }
    }


  }

  const selectedMonth = (range) => {
    var today = new Date();
    var checkPresent = moment(today, 'YYYY/MM/DD');
    var monthPresent = checkPresent.format('MM');
    var dayPresent = checkPresent.format('DD');
    var yearPresent = checkPresent.format('YYYY');
    const startPresent = `${yearPresent}-${monthPresent}-01`;

    var check = moment(range, 'YYYY/MM/DD');
    var month = check.format('MM');
    var day = check.format('DD');
    var year = check.format('YYYY');
    let noOfDays = moment(`${year}-${month}`, "YYYY-MM").daysInMonth()
    setYearForBS(moment(range.start).format('YYYY'))
    if (month === monthPresent) {
      var start = `${year}-${month}-${day}`;
    } else {
      var start = `${year}-${month}-01`;
    }

    const currentDate = `${year}-${month}-01`;
    const end = `${year}-${month}-${noOfDays}`;
    const rangeObj = { start, end }
    if (startPresent <= currentDate) {
      // dispatch(actions.fetchBookingByMonth(rangeObj, hatchery.permittedSpeciesId.name));
      console.log('Conditions not required in Dashboard')
    } else {
      console.log('Conditions not required in Dashboard it just utilization view')
    }
  }

  const addNewEventAlert = (slotInfo) => {
    console.log(slotInfo)
  }

  const { bookingByMonthData } = useSelector(
    (state) => ({
      bookingByMonthData: state.newbookings.bookingByMonthData,
    }),
    shallowEqual
  );

  const populateCubicleDetails = () => {
    setTotalBiomass((enterTOtalNoOfImport * EnterBiomass) / 1000)
    axios.get(`/bookings/populate-cubicle-details?bioMassPerStock=${EnterBiomass}&totalNumberOfStock=${enterTOtalNoOfImport}`)
      .then(({ data }) => {
        setRequiredCubicals(data.cubicleRequired)
        setTotalAmount(data.totalAmount)
      })
      .catch((err) => {
        if (err.response) {
          console.log('Conditions not required in Dashboard it just utilization view')
        }
      });
  };

  const [events, setEvents] = useState([
    {
      title: "Click for Creative Tim",
      start: "2021-01-20",
      end: "2021-01-20",
      color: "orange",
    }
  ]);

  const saveNewBooking = (values) => {
    const data = {
      "supplierId": values.suppliers,
      "cubicleBookingScheduleIds": selectedCubical,
      "bioMassPerStock": parseInt(EnterBiomass),
      "totalNumberOfStock": enterTOtalNoOfImport,
      "declarationLetter": declarationDocument,
    }


  }
  useEffect(() => {
    if (bookingByMonthData) {
      if (bookingByMonthData.schedules.length == 0) {
        console.log('Conditions not required in Dashboard it just utilization view')

      }
      if (bookingByMonthData.status != "Blocked") {
        setCubicalsAvailable(0);
        setCubicalsNotAvailable(0);
        setCubicalsBlocked(0);
        const eventNewList = [];
        bookingByMonthData.schedules.map(cubicle => {
          //&& cubicle.speciesId.name === hatchery.permittedSpeciesId.name

          if (cubicle.status === "Available") {
            setCubicalsAvailable(cubicalsAvailable + 1);
            eventNewList.push({
              title: cubicle.cubicleId.id,
              scheduleId: cubicle.id,
              start: cubicle.availableDate,
              end: cubicle.availableDate,
              // color: "orange",
              color: "green",
            })
          }
          //&& cubicle.speciesId.name === hatchery.permittedSpeciesId.name
          if (cubicle.status === "Blocked") {
            setCubicalsNotAvailable(cubicalsNotAvailable + 1);
            eventNewList.push({
              title: cubicle.cubicleId.id,
              // id:cubicle.cubicleId.id,
              scheduleId: cubicle.id,
              start: cubicle.availableDate,
              end: cubicle.availableDate,
              // color: "orange",
              color: "yellow",
            })
          }
          //&& cubicle.speciesId.name === hatchery.permittedSpeciesId.name
          if (cubicle.status === "Confirmed") {
            setCubicalsBlocked(cubicalsBlocked + 1);
            eventNewList.push({
              title: cubicle.cubicleId.id,
              // id:cubicle.cubicleId.id,
              scheduleId: cubicle.id,
              start: cubicle.availableDate,
              end: cubicle.availableDate,
              // color: "orange",
              color: "red",
            })
          }
        })
        setEvents(eventNewList)
      } else {
        console.log('Conditions not required in Dashboard it just utilization view')
      }
    }

  }, [bookingByMonthData]);

  const handleTotalNoOfImport = (data) => {
    setEnterTOtalNoOfImport(data)
    setSelectedCubical([])
    setCubicalsAddLimit(0)
    setCubicals([])

  }

  const handleBiomass = (data) => {
    setEnterBiomass(data)
  }
  const eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  }

  useEffect(() => {
    var today = new Date();
    var checkPresent = moment(today, 'YYYY/MM/DD');
    var monthPresent = checkPresent.format('MM');
    var dayPresent = checkPresent.format('DD');
    var yearPresent = checkPresent.format('YYYY');
    const startPresent = `${yearPresent}-${monthPresent}-01`;


    let noOfDays = moment(`${yearPresent}-${monthPresent}`, "YYYY-MM").daysInMonth()
    // setYearForBS(moment(range.start).format('YYYY'))
    const start = `${yearPresent}-${monthPresent}-${dayPresent}`;
    const end = `${yearPresent}-${monthPresent}-${noOfDays}`;
    const rangeObj = { start, end }
    dispatch(actions.fetchBookingByMonth(rangeObj, speciesId));
    dispatch(actions.fetchSuppliers());

  }, [speciesId, dispatch]);

  useEffect(() => {
    if (enterTOtalNoOfImport != null && EnterBiomass != null && EnterBiomass >= 20)
      // populateCubicleDetails(enterTOtalNoOfImport, EnterBiomass)
      populateCubicleDetails()
  }, [enterTOtalNoOfImport, EnterBiomass, dispatch]);


  const handleDeclarationDocumentUpload = (document) => {
    const selectedFile = document;
    var formdata = new FormData();
    if (selectedFile && selectedFile.name) {

      formdata.append("files", selectedFile, selectedFile.name);
      axios.post(process.env.REACT_APP_UPLOAD_PATH, formdata)
        .then(({ data }) => {
          setDeclarationDocument(data[0].url)
          // disableLoading();
        })
        .catch(() => {
          // disableLoading();
        });
    }
  }


  function handleSpeciesType(event) {
    setSpeciesId(event)
  }

  const BookingEditSchema = Yup.object().shape({
    EnterTOtalNoOfImport: Yup.number()
      .max(1250)
      .required(" ")
  });

  // fetchBookingByMonth
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={booking}
        //  validationSchema={BookingEditSchema}
        onSubmit={(values) => {
          saveNewBooking(values);
        }}
      >
        {({ handleSubmit }) => (
          <>


            <div className="form-group row" style={{ height: "600px", width: "800px" }}>
              <div class="col-md-4 offset-md-3" style={{ marginBottom: '15px' }} >

                <label style={{ marginLeft: '25px' }}>Filter By Species Type</label>
                <SelectComp
                  name="speciesId"
                  value={speciesId}
                  onChange={(e) => handleSpeciesType(e.target.value)}
                >
                  <option value="Select">Select..</option>
                  <option value="1">L. vannamei Broodstock</option>
                  <option value="2">L. monodone Broodstock</option>
                  <option value="3">P. monodone PPL</option>

                </SelectComp><hr></hr>
              </div>
              <BigCalendar
                events={events}
                views={allViews}
                step={60}
                showMultiDayTimes
                // max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
                style={{ height: "500px", width: "800px" }}
                components={{
                  timeSlotWrapper: ColoredDateCellWrapper,
                }}
                popup
                selectable
                localizer={localizer}
                defaultView="month"
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={(event) => selectedEvent(event)}
                onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                onNavigate={(range) => selectedMonth(range)}
                eventPropGetter={
                  (event, start, end, isSelected) => {
                    let newStyle = {
                      backgroundColor: "green",
                      color: 'white',
                      borderRadius: "0px",
                      border: "none"
                    };
                    if (event.color === "red") {
                      newStyle.backgroundColor = "red"
                    }
                    if (event.color === "green") {
                      newStyle.backgroundColor = "green"
                    }
                    if (event.color === "yellow") {
                      newStyle.backgroundColor = "yellow";
                      newStyle.color = "black"
                    }
                    return {
                      className: "",
                      style: newStyle
                    };
                  }
                }
              />
            </div>

          </>
        )}
      </Formik>
    </>
  );
}
