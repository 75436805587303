import React, { useEffect, useState } from "react";
import EveryDayStockComponent from "./SummaryCardComponent";

import axios from "axios";



export default function CubiclesPage() {

    const [everyDayStock, setEveryDayStock] = useState([]);
    useEffect(() => {
        fetchEverydayStock()
    }, []);


    async function fetchEverydayStock() {
        try {

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            // for demo use '2021-03-19'
            today = yyyy + '-' + mm + '-' + dd;

            await axios.get(`cubicle-stockings/everydaystock?date=${today}`)
                .then(res => {
                    let data = [];
                    res.data.forEach((item) => {
                        data.push(
                            <EveryDayStockComponent
                                title={item.cubicleId}
                                stock={item.totalAnimalStocked}
                                weight={`${item.totalStockGms} Grams`}
                                bookingId={item.bookingId}
                                hatchery={item.hatchery}
                                fromDate={item.fromDate}
                                toDate={item.toDate}
                            />,
                        )
                    })
                    setEveryDayStock(data);

                })
        } catch (error) {
            console.log(error)

        }
    }


    return (
        <div className="card-header border-0">
            <h3 className="card-title font-weight-bolder ">Current Stock Status</h3>
            <div class="row">
                {everyDayStock}
            </div>
        </div>
    )
}

