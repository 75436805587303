import {createSlice} from "@reduxjs/toolkit";

const initialBookingChangesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  bookingChangeForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const bookingChangesSlice = createSlice({
  name: "bookingChanges",
  initialState: initialBookingChangesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getBookingChangeById
    bookingChangeFetched: (state, action) => {
      state.actionsLoading = false;
      state.bookingChangeForEdit = action.payload.bookingChangeForEdit;
      state.error = null;
    },
    // findBookingChanges
    bookingChangesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    pastBookingChangesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createBookingChange
    bookingChangeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.bookingChange);
    },
    // updateBookingChange
    bookingChangeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.bookingChange.id) {
          return action.payload.bookingChange;
        }
        return entity;
      });
    },
    // deleteBookingChange
    bookingChangeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteBookingChanges
    bookingChangesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // bookingChangesUpdateState
    bookingChangesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
