/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/WebsocLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const notifications = useSelector((state) => state.dashboardData.notifications, shallowEqual);
  const notificationsCount = useSelector((state) => state.dashboardData.notificationsCount, shallowEqual);

console.log(notifications,"notifications")

  return (
    <>
      {
        layoutProps.offcanvas &&
        (
          <div className="topbar-item">
            <div
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              id="kt_quick_notifications_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
              </span>
              <span className="pulse-ring"></span>
            </div>
          </div>
        )
      }
      {
        !layoutProps.offcanvas &&
        (
          <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
              as={DropdownTopbarItemToggler}
              id="kt_quick_notifications_toggle"
            >
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="user-notification-tooltip">
                    User Notifications
                </Tooltip>
                }
              >
                <div
                  className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                  id="kt_quick_notifications_toggle"
                >
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                    />
                  </span>
                  <span className="pulse-ring"></span>
                  <span className="pulse-ring" />
                </div>
              </OverlayTrigger>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
              <form>
                {/** Head */}
                <div
                  className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                  style={{ backgroundImage: `url(${bgImage})` }}
                >
                  <h4 className="d-flex flex-center rounded-top">
                    <span className="text-white">User Notifications</span>
                    <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                      {notificationsCount}
                    </span>
                  </h4>

                  <Tab.Container defaultActiveKey={key}>
                    <Nav
                      as="ul"
                      className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                      onSelect={(_key) => setKey(_key)}
                    >
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          eventKey="Alerts"
                          className={`nav-link show ${key === "Alerts" ? "active" : ""
                            }`}
                        >
                          Notifications List
                      </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content className="tab-content">
                      <Tab.Pane eventKey="Alerts" className="p-8">
                        <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          className="scroll pr-7 mr-n7"
                          style={{ maxHeight: "300px", position: "relative" }}
                        >
                          {notifications && notifications.map((eachNotification, i) => (
                            <div className="d-flex align-items-center mb-6" key={i}>
                              {eachNotification.read === false &&
                                <>
                                  <div className="symbol symbol-40 symbol-light-warning mr-5">
                                    <span className="symbol-label">

                                    </span>
                                  </div>
                                  <div className="d-flex flex-column font-weight-bold">
                                    <a
                                      href="#"
                                      className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                                    >

                                    </a>
                                    <span className="text-muted">
                                      {eachNotification.notificationString}
                                    </span>
                                  </div>
                                </>
                              }
                            </div>

                          ))}

                        </PerfectScrollbar>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="Events"
                        id="topbar_notifications_events"
                      >
                        <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          className="navi navi-hover scroll my-4"
                          style={{ maxHeight: "300px", position: "relative" }}
                        >
                          <a href="#" className="navi-item">
                            <div className="navi-link">
                              <div className="navi-icon mr-2">
                                <i className="flaticon2-line-chart text-success"></i>
                              </div>
                              <div className="navi-text">
                                <div className="font-weight-bold">
                                  New report has been received
                              </div>
                                <div className="text-muted">23 hrs ago</div>
                              </div>
                            </div>
                          </a>

                          <a href="#" className="navi-item">
                            <div className="navi-link">
                              <div className="navi-icon mr-2">
                                <i className="flaticon2-analytics-1 text-success"></i>
                              </div>
                              <div className="navi-text">
                                <div className="font-weight-bold">
                                  New customer is registered
                              </div>
                                <div className="text-muted">3 days ago</div>
                              </div>
                            </div>
                          </a>
                        </PerfectScrollbar>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
                        <div className="d-flex flex-center text-center text-muted min-h-200px">
                          All caught up!
                        <br />
                        No new notifications.
                      </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </form>
            </Dropdown.Menu>
          </Dropdown>
        )
      }
    </>
  );
}
