import {createSlice} from "@reduxjs/toolkit";

const initialMasterCalendarsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  checkMonthBlockedCount:0,
  checkMonthBlockedEntities:[],
  calendarSchedule:[],
  masterCalendarForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const masterCalendarsSlice = createSlice({
  name: "masterCalendarData",
  initialState: initialMasterCalendarsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getMasterCalendarById
    masterCalendarFetched: (state, action) => {
      state.actionsLoading = false;
      state.masterCalendarForEdit = action.payload.masterCalendarForEdit;
      state.error = null;
    },
    // findMasterCalendars
    masterCalendarsFetched: (state, action) => {
      const { totalCount, entities, queryParams } = action.payload;
      state.listLoading = false;
      state.error = null;
	  // process data and group by month and species.
      state.entities = entities;
      state.totalCount = totalCount;
    },

    fetchedCheckMonthBlocked: (state, action) => {
      console.log('fetchedCheckMonthBlocked', action);
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.checkMonthBlockedEntities = entities;
      state.checkMonthBlockedCount = entities ? entities.count : 0;
    },
    calendarScheduleFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      console.log(totalCount, entities,"in sliceeeeee")
      state.listLoading = false;
      state.error = null;
      state.calendarSchedule = entities;
      state.totalCount = totalCount;
    },
    cubiclesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.cubiclesData = entities;
      state.cubicalsCount = totalCount;
    },
    SpeciesDetailsFetched: (state, action) => {
      const { totalCount1, entities1 } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.speciesData = entities1;
      state.speciesCount = totalCount1;
    },
    // createMasterCalendar
    masterCalendarCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.masterCalendar);
    },
    // updateMasterCalendar
    masterCalendarUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.masterCalendar.id) {
          return action.payload.masterCalendar;
        }
        return entity;
      });
    },

    updatedMonthStatusOfCalendar: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.masterCalendar.id) {
      //     return action.payload.masterCalendar;
      //   }
      //   return entity;
      // });
    },
    
    // deleteMasterCalendar
    masterCalendarDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteMasterCalendars
    masterCalendarsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // masterCalendarsUpdateState
    masterCalendarsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
