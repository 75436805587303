import React, { useState } from 'react';
import IdleTimer, { useIdleTimer } from 'react-idle-timer'
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap';


export default function IdealTimeout() {

    const [showTimeoutModel, setTimeoutModel] = useState(false);

    const handleClose = () => setTimeoutModel(false);

    const handleOnIdle = event => {
        console.log('last active', getLastActiveTime())
        setTimeoutModel(true)
        window.location.replace("/logout");
    }

    const handleOnActive = event => {
        reset()
        setTimeoutModel(false)
        console.log('time remaining', getRemainingTime())
    }

    const handleOnAction = event => {
        reset()
        setTimeoutModel(false)
    }


    // Time is in milliseconds

    const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
        timeout: 1800000,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    return (
        <div>

            <Modal show={showTimeoutModel} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Session Timeout!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your session is about to expire in 5 seconds due to inactivity. You will be redirected to the login page if inactivity continues</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}
