import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';


/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  identifier: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [captchaValidation, setCaptchaValidation] = useState(false);

  const LoginSchema = Yup.object().shape({
    identifier: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };


  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        if (captchaValidation === false) {
          setStatus('Captcha Required')
          disableLoading();
          setSubmitting(false)
        } else {
          login(values.identifier, values.password)
            .then(response => {
              if (response.status !== 200) {
                throw Error(response.statusText);
              }
              return response.data;
            })
            .then(data => {
              disableLoading();
              props.fulfillUser(data.user, data.jwt);
            })
            .catch((error) => {
              disableLoading();
              setSubmitting(false);
              if (error.response) {
                if (Array.isArray(error.response.data.data) &&
                  Array.isArray(error.response.data.data[0].messages)) {
                  setStatus(error.response.data.data[0].messages[0].message);
                } else {
                  setStatus(error.response.data.message);
                }
              } else {
                setStatus("Exceeded maximum allowed logins for this user");
              }
            });
        }
      }, 1000);
    },
  });


  function onChange(value) {

    let payload = {
      captcha: value
    }

    axios.post('/settings/verify-google-captcha', payload)
      .then(response => {
        setCaptchaValidation(true)
      })
      .catch(error => {
        console.log(error.response)
      });

  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        {/* <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3> */}
        <p className="text-muted font-weight-bold">
          Enter your Credentials
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible" style={{ marginLeft: "12%", width: "76%" }}>
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10">
            {/* <div className="alert-text ">
              Use account <strong>admin@demo.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div> */}
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            style={{ width: "75%", marginLeft: "12%" }}
            autoComplete="off"
            placeholder="Identifier"
            type="identifier"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "identifier"
            )}`}
            name="identifier"
            {...formik.getFieldProps("identifier")}
          />
          {formik.touched.identifier && formik.errors.identifier ? (
            <div className="fv-plugins-message-container" >
              <div className="fv-help-block" style={{ marginLeft: "12%" }}>{formik.errors.identifier}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            style={{ width: "75%", marginLeft: "12%" }}
            autoComplete="off"
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block" style={{ marginLeft: "12%" }}>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <Link
          style={{ marginLeft: "60%" }}
          to="/auth/forgot-password"
          className="text-dark-50 text-hover-primary my-3 mr-2 "
          id="kt_login_forgot"
        >
          <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
        </Link>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={onChange}
          />
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">

          <button
            style={{ marginLeft: '60%' }}
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
