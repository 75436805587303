import * as requestFromServer from "./dashboardCrud";
import { dashboardSlice, callTypes } from "./dashboardSlice";

const { actions } = dashboardSlice;

export const fetchUserRoleAccess = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserRolesAndAccess(queryParams.role.id)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedUserRoleAccess({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchCubicles = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllCubicals(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.data.length;
      dispatch(actions.cubiclesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find cubicles";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchNotifications = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNotifications(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.data.length;
      dispatch(actions.fetchedNotifications({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchHatcheries = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllHatcheries(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.data.length;
      dispatch(actions.fetchedHatcheries({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find SpeciesDetails";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSuppliers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllSuppliers(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.data.length;
      dispatch(actions.fetchedSuppliers({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find SpeciesDetails";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSpeciesDetails = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllSpeciesDetails(queryParams)
    .then(response => {
      const entities1 = response.data;
      const totalCount1 = response.data.length;
      dispatch(actions.SpeciesDetailsFetched({ totalCount1, entities1 }));
    })
    .catch(error => {
      error.clientMessage = "Can't find SpeciesDetails";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchBooking = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllBookingsCount(queryParams)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedBooking({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCountry = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCountry(queryParams)
    .then(response => {
      const entities = response.data;
      dispatch(actions.fetchCountry({ entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Countries ";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBookingsForCC = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllBookings(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.data.length;
      dispatch(actions.fetchedBookingsForCC({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Booking";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLARCount = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLARCount(queryParams)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedLARCount({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchHatcheriesCount = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getHatcheriesCount(queryParams)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedHatcheriesCount({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchBookingVsSpecies = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getBookingVsSpecies(queryParams)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedBookingVsSpecies({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchTotalVsOpenBookings = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTotalVsOpenBookings(queryParams)
    .then(response => {
      const totalCount = response.data;
      dispatch(actions.fetchedTotalVsOpenBookings({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchTotalVsOpenLAR = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTotalVsOpenLAR(queryParams)
    .then(response => {
      const totalCount = response.data.length;
      const entities = response.data;
      dispatch(actions.fetchedTotalVsOpenLAR({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


