import axios from "axios";

export const PRODUCTS_STATUSBASED_URL = "/bookings?status=confirmed&&arrivalReport=true";
export const PRODUCTS_URL = "/bookings";
export const PRODUCTS_GET_URL = "/bookings?_start=0&&_limit=10&&_sort=id:desc";

export const PAST_PRODUCTS_URL = "bookings?_start=10";
export const GENERATE_NOC_URL = "bookings/generate-noc";
// CREATE =>  POST: add a new booking to the server
export function createBooking(booking) {
  const bookingId = booking.id;
  console.log(bookingId, booking)
  return axios.get(`${GENERATE_NOC_URL}/${bookingId}`);
}

export function getConfirmedBookings() {
  return axios.get(PRODUCTS_STATUSBASED_URL);
}

// READ
export function getAllBookings() {
  return axios.get(PRODUCTS_GET_URL);
}

export function getAllPastBookings() {
  return axios.get(PAST_PRODUCTS_URL);
}

export function getBookingById(bookingId) {
  return axios.get(`${PRODUCTS_URL}/${bookingId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBookings(queryParams) {
  return axios.post(`${PRODUCTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateBooking(booking) {
  return axios.put(`${PRODUCTS_URL}/${booking.id}`, { booking });
}

// UPDATE Status
export function updateStatusForBookings(ids, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForBookings`, {
    ids,
    status
  });
}



// DELETE => delete the booking from the server
export function deleteBooking(bookingId) {
  return axios.delete(`${PRODUCTS_URL}/${bookingId}`);
}

// DELETE Bookings by ids
export function deleteBookings(ids) {
  return axios.post(`${PRODUCTS_URL}/deleteBookings`, { ids });
}
