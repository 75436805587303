import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    sortCaret,
} from "../../../../_websoc/_helpers";
import axios from 'axios';
import { Input, Select as SelectComp } from "../../../../_websoc/_partials/controls";
import { Formik, Field } from "formik";


const columns = [
    {
        dataField: "name",
        text: "Hatchery Name",
        sort: true,
        sortCaret: sortCaret,
    },
    {
        dataField: "bookingCount",
        text: "No Of Bookings",
        sort: true,
        sortCaret: sortCaret,
    },
    {
        dataField: "noOfBroodsStockPerYear",
        text: "Permit Limit",
        sort: true,
        sortCaret: sortCaret,
    },
    {
        dataField: "totalAmount",
        text: "Amount Paid",
        sort: true,
        sortCaret: sortCaret,
    },
    {
        dataField: "state",
        text: "State",
        sort: true,
        sortCaret: sortCaret,
    },
];


const initialValues = () => {
	var today = new Date();
	var dd = String(today.getDate()).padStart(2, '0');
	var mm = String(today.getMonth() + 1).padStart(2, '0');
	var yyyy = today.getFullYear();
	
	return {
		toDate : yyyy + '-' + mm + '-' + dd,
		fromDate : yyyy + '-' + mm + '-01',
		supplier: '',
        hatchery: "",
        stateId : '',
		districtId : ''
    };
};


export default function RevenueTable() {


    const { currentState } = useSelector(
        (state) => ({ currentState: state.bookings }),
        shallowEqual
    );

    const { currentDashboardState } = useSelector(
        (state) => ({ currentDashboardState: state.dashboardData }),
        shallowEqual
    );
    const [tableContent, setTableContent] = useState([]);
    const [statesData, setStatesData] = useState([]);
	const [districtsData, setDistrictsData] = useState([]);
	const { totalCount, entities, listLoading } = currentState;


    useEffect(() => {
		if (!statesData.length) {
	      axios.get(`${'settings?type=STATE'}`)
            .then(res => {
                setStatesData(res.data)
            }).catch(err => {
                console.log('err')
            });
			fetchHatcheryWiseRevenue(initialValues())
		}
    }, []);

    async function fetchHatcheryWiseRevenue(values) {
        try {
			await axios.get(`transactions/revenuebyhatchery?fromDate=${values.fromDate}&toDate=${values.toDate}`
				+ (values.stateId ? `&state=${values.stateId}` : '')
				+ (values.districtId ? `&district=${values.districtId}` : '')
			).then(res => {
				setTableContent(res.data)
			})
		} catch (error) {
            console.log('error')
        }
    }
	
	function handleStates(state) {
	   axios.get(`settings?type=DISTRICT&parent=${state}`)
            .then(res => {
                setDistrictsData(res.data)
            }).catch(err => {
                console.log('err')
            })
    }
	
    return (
        <div>
            <Formik
                initialValues= {initialValues()}
                onSubmit={(values) => {
                    fetchHatcheryWiseRevenue(values);
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
					values
                }) => (
                    <form onSubmit={handleSubmit} className="form form-label-right">
                        <div className="form-group row">
                            <div className="col-lg-2">
                                <label>Select State</label>
                                <SelectComp
                                    name="stateId"
									onChange={(e) => { handleChange(e); handleStates(e.target.value);}}
                                >
                                    <option>Select...</option>
                                    {statesData && statesData.map((states) => (
                                        <option key={states.id} value={states.id}>
                                            {states.value}
                                        </option>
                                    ))}

                                </SelectComp>
                                <small className="form-text text-muted">
                                    {/* <b>Filter</b> by Booking Status */}
                                </small>
                            </div>
							<div className="col-lg-2">
                                <label>Select District</label>
                                <SelectComp
                                    name="districtId"
								>
                                    <option>Select...</option>
                                    {districtsData && districtsData.map((district) => (
                                        <option key={district.id} value={district.id}>
                                            {district.value}
                                        </option>
                                    ))}

                                </SelectComp>
                                <small className="form-text text-muted">
                                    {/* <b>Filter</b> by Booking Status */}
                                </small>
                            </div>
                            <div className="col-lg-2">
                                <Field
                                    type="date"
                                    name="fromDate"
                                    component={Input}
                                    placeholder="From Date"
                                    label="From Date "
                                />
                            </div>
                            <div className="col-lg-2">
                                <Field
                                    type="date"
                                    name="toDate"
                                    component={Input}
                                    placeholder="To Date"
                                    label="To Date"
                                />
                            </div>
                            <div className="col-lg-2">
                                <div>&nbsp;</div>
                                <button
                                    type="submit"
                                    className="btn btn-primary ml-2"
                                // onClick={saveReportClick}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>

            <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                keyField="id"
                data={tableContent}
                columns={columns}
            />
        </div>

    )
}
