import React from 'react'
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../controls";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';


export default function SummaryCardComponent(props) {

    const { title, stock, bookingId, weight, hatchery, fromDate, toDate } = props;
    const [additionalDetailsModel, setAdditionalDetailsModel] = React.useState(false);

    const handleModel = () => {
        if (bookingId != null) {
            setAdditionalDetailsModel(true)
        }
    }

    return (
        <div className="pt-5 col-sm-3 col-xs-12 col-lg-3">
			<Card onClick={handleModel}>
				<CardHeader className={stock > 0 ? 'btn btn-danger' : 'btn btn-success'} title={`Cubicle No : ${title}`} />
				<CardBody className={stock > 0 ? 'text-warning' : 'text-success'}>
                    <p> {`Total Stock :${stock}`} </p>
                    <p> {`Total Weight : ${weight}`} </p>		
                </CardBody>
            </Card>
            
			<Modal
                show={additionalDetailsModel}
                onHide={() => setAdditionalDetailsModel(false)}
                dialogClassName="modal-90w"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {`CUBICLE No : ${title}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> {`Total Stock - ${stock}`} </p>
                    <p> {`Total Weight - ${weight}`} </p>
					{
							stock > 0 &&
							<>
								<p> {`Hatchery : ${hatchery}`} </p>
								<p> {`Booking ID : ${bookingId}`} </p>
								<p> {`Start Date: ${moment(fromDate).format('YYYY-MM-DD')}`} </p>
								<p> {`End Date: ${moment(toDate).format('YYYY-MM-DD')}`} </p>
							</>
                        }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setAdditionalDetailsModel(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}
