import React, { useEffect, useMemo } from "react";
import {
    MixedWidget1,
    MixedWidget14,
    MixedWidget4,
    MixedWidget22,
    MixedWidget15,
    ListsWidget9,
    StatsWidget10,
    ListsWidget1,
    AdvanceTablesWidget2,
    AdvanceTablesWidget4,
    TilesWidget11,
    TilesWidget21,
    TilesWidget22,
} from "../widgets";
import {
    StatsWidget11,
    StatsWidget12,
} from "../widgets2";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../app/modules/DashboardAPI/dashboardActions";
import { BookingCreateForm } from "../../../app/pages/cubicle-utilization/BookingCreateForm";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../controls";
import { BookingsTable } from "../../../app/modules/Bookings/pages/Bookings/bookings-table/BookingsTable";
import TopTenHatcheryTable from "../../../app/pages/cubicle-utilization/Top-ten-Hatcheries/TopTenHatcheryTable";
import RevenueTable from "../../../app/pages/cubicle-utilization/hatchery-revenue/RevenueTable";
import StateWiseTable from "../../../app/pages/cubicle-utilization/StateWiseHatchery/StateWiseTable";
import CubiclesPage from './cubicle-summary/CubiclesPage'


export function DashboardWebsoc() {

    const { permissions } = useSelector(
        (state) => {
            let permissions = {};
            if (state.dashboardData.userRoleAccess) {
                let appPermissions = state.dashboardData.userRoleAccess.role.permissions.application.controllers;
                permissions['larCount'] = appPermissions['lab-analysis-report'].count.enabled;
                permissions['bookingsCount'] = appPermissions['booking'].count.enabled;
                permissions['hatcheryCount'] = appPermissions['hatchery'].count.enabled;
                permissions['speciesWiseImported'] = appPermissions['booking'].specieswiseimported.enabled;
                permissions['cubicleStock'] = appPermissions['cubicle-stocking'].everydaystock.enabled;
                permissions['cubicleSchedules'] = appPermissions['cubicle-booking-schedules'].mastercalendar.enabled;
                permissions['topHatcheries'] = appPermissions['booking'].totalbyhatchery.enabled;
                permissions['hatcheryRevenues'] = appPermissions['transactions'].revenuebyhatchery.enabled;
            }
            return { permissions };
        },
        shallowEqual
    );


    const { user } = useSelector(
        (state) => ({ user: state.auth.user }),
        shallowEqual
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatchDashbordAPICall()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);


    async function dispatchDashbordAPICall() {
        await Promise.all([
            dispatch(actions.fetchUserRoleAccess(user)),
            dispatch(actions.fetchBookingsForCC()),
            dispatch(actions.fetchBooking()),
            dispatch(actions.fetchCountry()),
            dispatch(actions.fetchLARCount()),
            dispatch(actions.fetchHatcheriesCount()),
            dispatch(actions.fetchTotalVsOpenBookings()),
            dispatch(actions.fetchTotalVsOpenLAR()),
            dispatch(actions.fetchNotifications()),
            dispatch(actions.fetchHatcheries()),
            dispatch(actions.fetchSuppliers()),
        ]);
    }




    // const layoutDispatch = useContext(LayoutContext.Dispatch);
    const { cubicalsCount, speciesCount, cubiclesData, speciesData } = useSelector(
        (state) => ({

            cubicalsCount: state.dashboardData.cubicalsCount,
            speciesCount: state.dashboardData.speciesCount,
            cubiclesData: state.dashboardData.cubiclesData,
            speciesData: state.dashboardData.speciesData,
        }),
        shallowEqual
    );
    useEffect(() => {
        dispatch(actions.fetchSpeciesDetails());
        dispatch(actions.fetchCubicles());

    }, [dispatch]);


    return (
        <>

            <div className="row">
                {
                    permissions['larCount'] &&
                    <div className="col">
                        <a href="/Lab-analysis-report/lar">
                            <TilesWidget11 className="card-stretch gutter-b" />
                        </a>
                    </div>
                }
                {
                    permissions['bookingsCount'] &&
                    <div className="col">
                        <a href="/Approvals/booking/list">
                            <TilesWidget21 className="card-stretch gutter-b" />
                        </a>
                    </div>
                }
                {
                    permissions['hatcheryCount'] &&
                    <div className="col-lg-4 col-xs-12">
                        <a href="/Hatcheries/list">
                            <TilesWidget22 className="card-stretch gutter-b" />
                        </a>
                    </div>
                }
            </div>

            <div className="row">
                {
                    permissions['speciesWiseImported'] &&
                    <div className="col">
                        <MixedWidget15 className="card-stretch gutter-b" />
                    </div>
                }
                {
                    permissions['bookingsCount'] &&
                    <div className="col">
                        <MixedWidget14 className="card-stretch gutter-b" />
                    </div>
                }
                {
                    permissions['larCount'] &&
                    <div className="col">
                        <MixedWidget22 className="card-stretch gutter-b" />
                    </div>
                }
            </div>
            {
                permissions['cubicleStock'] &&
                <div className="block-example" style={{ marginTop: "2%", marginBottom: '2%', padding: '1%' }}>
                    <CubiclesPage></CubiclesPage>
                </div>
            }
            <div>
                {
                    permissions['cubicleSchedules'] &&
                    <Card>
                        <CardHeader title="Cubicle Utilization">

                        </CardHeader>
                        <CardBody>
                            <div style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                                <BookingCreateForm />
                            </div>

                        </CardBody>
                    </Card>
                }
                {
                    permissions['topHatcheries'] &&
                    <Card>
                        <CardHeader title="Top Ten Hatcheries">

                        </CardHeader>
                        <CardBody>
                            <TopTenHatcheryTable />
                        </CardBody>
                    </Card>
                }
                {
                    permissions['hatcheryRevenues'] &&
                    <Card>
                        <CardHeader title="Hatchery Wise Revenue">

                        </CardHeader>
                        <CardBody>
                            <RevenueTable />

                        </CardBody>
                    </Card>
                }
                {
                    permissions['topHatcheries'] &&
                    <Card>
                        <CardHeader title="CAA Utilization">

                        </CardHeader>
                        <CardBody>
                            <StateWiseTable />
                        </CardBody>
                    </Card>
                }
            </div>
        </>
    );
}
