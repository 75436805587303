import axios from "axios";


export const BOOKINGS_URL = "/bookings";
export const HATCHERIES_URL = "/hatcheries?_limit=-1";
export const SUPPLIERS_URL = "/suppliers?_limit=-1";
export const NOTIFICATIONS_URL = "/notification-statuses";

export const USER_ROLE_PERMISSIONS = "auth/permissions";
export const BOOKINGS_COUNT_URL = "/bookings/count";
export const LAR_URL = "/lab-analysis-reports/count";
export const HATCHERIES_COUNT_URL = "/hatcheries/count";

export const BOOKINGSVSSPECIES_URL = "/species-wise-imported";
export const TOTALVSOPENBOOKINGS_URL = "/bookings?status=Confirmed";
export const TOTALVSOPENLAR_URL = "/lab-analysis-reports?approvalFlag=true&bookingId.status=Confirmed";

export const CUBICAL_URL = "/cubicles?_start=0&_limit=100";
export const SPECIES_URL = "/species-details";
export const GET_CALENDAR_SCHEDULE = "/cubicle-booking-schedules";





export function getAllBookingsCount() {
  return axios.get(BOOKINGS_COUNT_URL);
}

export function getCountry() {
  return axios.get(`${'settings'}?type=${'COUNTRY'}`);
}


// CREATE =>  POST: add a new product to the server
export function getNotifications() {
  return axios.get(NOTIFICATIONS_URL);
}


// READ
export function getAllCubicals() {
  return axios.get(CUBICAL_URL);
}
export function getAllSpeciesDetails() {
  return axios.get(SPECIES_URL);
}
export function getCalendarScheduleByDates(fromDate, toDate) {
  return axios.get(`${GET_CALENDAR_SCHEDULE}/?availableDate_gte=${fromDate}&availableDate_lte=${toDate}`);
}


export function getUserRolesAndAccess(id) {
  return axios.get(USER_ROLE_PERMISSIONS);
}

export function getAllBookings() {
  return axios.get(BOOKINGS_URL);
}
export function getAllHatcheries() {
  return axios.get(HATCHERIES_URL);
}
export function getAllSuppliers() {
  return axios.get(SUPPLIERS_URL);
}
export function getLARCount() {
  return axios.get(LAR_URL);
}
export function getHatcheriesCount() {
  return axios.get(HATCHERIES_COUNT_URL);
}
export function getBookingVsSpecies() {
  return axios.get(BOOKINGSVSSPECIES_URL);
}
export function getTotalVsOpenBookings() {
  return axios.get(TOTALVSOPENBOOKINGS_URL);
}
export function getTotalVsOpenLAR() {
  return axios.get(TOTALVSOPENLAR_URL);
}


