import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_websoc/layout";
import { DashboardPage } from "./pages/DashboardPage";

const LabAnalysisReport = lazy(() =>
  import("./modules/LabAnalysisReport/pages/LabAnalysisReportPage")
);

const Metrics = lazy(() =>
  import("./modules/Metrics/pages/MetricsPage")
);

const BookingsPage = lazy(() =>
  import("./modules/Bookings/pages/BookingsPage")
);
const PastBookingsPage = lazy(() =>
  import("./modules/PastBookings/pages/PastBookingsPage")
);

const ClearanceCertificate = lazy(() =>
  import("./modules/ClearanceCertificate/pages/ClearanceCertificatePage")
);

const UserAccounts = lazy(() =>
  import("./modules/UserAccounts/pages/UserAccountsPage")
);

const UserProfilePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const MasterCalendarPage = lazy(() =>
  import("./modules/MasterCalendar/pages/MasterCalendarPage")
);

const ReportsPage = lazy(() =>
  import("./modules/Reports/pages/ReportsPage")
);

const Reports2Page = lazy(() =>
  import("./modules/Reports2/pages/ReportsPage")
);
const RoleManagementPage = lazy(() =>
  import("./modules/RoleManagement/pages/RoleManagementPage")
);

const SuppliersPage = lazy(() =>
  import("./modules/Suppliers/pages/SuppliersPage")
);

const ApprovalsPage = lazy(() =>
  import("./modules/Approvals/pages/ApprovalsPage")
);

const HatcheriesPage = lazy(() =>
  import("./modules/Hatcheries/pages/HatcheriesPage")
);

const UnRegisteredHatcheriesPage = lazy(() =>
  import("./modules/Hatcheries/pages/HatcheriesPage")
);

const ExpiredHatcheriesPage = lazy(() =>
  import("./modules/Hatcheries/pages/HatcheriesPage")
);

const TestsPage = lazy(() =>
  import("./modules/Tests/pages/TestsPage")
);

const PaymentsInvoicesPage = lazy(() =>
  import("./modules/Transactions/pages/TransactionsPage")
);

const PaymentsReceiptsPage = lazy(() =>
  import("./modules/PaymentReceipts/pages/PaymentsReceiptsPage")
);


const AdditionalChargesPage = lazy(() =>
  import("./modules/AdditionalCharges/pages/AdditionalChargPage")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />

        <Route path="/Lab-analysis-report" component={LabAnalysisReport} />
        <Route path="/metrics" component={Metrics} />
        <Route path="/clearence-certificate" component={ClearanceCertificate} />
        <Route path="/Bookings" component={BookingsPage} />
        <Route path="/past-bookings" component={PastBookingsPage} />
        <Route path="/UserAccounts" component={UserAccounts} />
        <Route path="/user-profile" component={UserProfilePage} />
        <Route path="/MasterCalendar" component={MasterCalendarPage} />
        <Route path="/Reports" component={ReportsPage} />
        <Route path="/reports-others" component={Reports2Page} />
        <Route path="/RoleManagement" component={RoleManagementPage} />
        <Route path="/Approvals" component={ApprovalsPage} />
        <Route path="/Suppliers" component={SuppliersPage} />
        <Route path="/hatcheries" component={HatcheriesPage} />
        <Route path="/unregistered-hatcheries" component={UnRegisteredHatcheriesPage} />
        <Route path="/expired-hatcheries" component={ExpiredHatcheriesPage} />
        <Route path="/Tests" component={TestsPage} />
        <Route path="/transactions" component={PaymentsInvoicesPage} />
        <Route path="/payment-receipts" component={PaymentsReceiptsPage} />
        <Route path="/additional-charges" component={AdditionalChargesPage} />
      </Switch>
    </Suspense>
  );
}
