import {createSlice} from "@reduxjs/toolkit";

const initialClearanceCertificatesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  bookings: null,
  clearanceCertificateForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const clearanceCertificatesSlice = createSlice({
  name: "clearanceCertificates",
  initialState: initialClearanceCertificatesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getClearanceCertificateById
    clearanceCertificateFetched: (state, action) => {
      state.actionsLoading = false;
      state.clearanceCertificateForEdit = action.payload.clearanceCertificateForEdit;
      state.error = null;
    },
    // findClearanceCertificates
    clearanceCertificatesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    bookingsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bookings = entities;
      // state.totalCount = totalCount;
      console.log(state.bookings,"bookings console")
    },


    // createClearanceCertificate
    clearanceCertificateCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.clearanceCertificate);
    },
    // updateClearanceCertificate
    clearanceCertificateUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.clearanceCertificate.id) {
          return action.payload.clearanceCertificate;
        }
        return entity;
      });
    },
    // deleteClearanceCertificate
    clearanceCertificateDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteClearanceCertificates
    clearanceCertificatesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // clearanceCertificatesUpdateState
    clearanceCertificatesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
