/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useDispatch, shallowEqual, useSelector } from "react-redux";


export function AsideMenuList({ layoutProps }) {
  const location = useLocation();

  const [ModuleLevelAccessRestriction, setModuleLevelAccessRestriction] = useState(null);
  const [labAnalysisAccess, setLabAnalysisAccess] = useState(null);
  const [bookingsAccess, setBookingsAccess] = useState(null);
  const [certificatesAccess, setCertificatesAccess] = useState(null);
  const [userAccountsAccess, setUserAccountsAccess] = useState(null);
  const [userRolesAccess, setUserRolesAccess] = useState(null);
  const [reportsAccess, setReportsAccess] = useState(null);
  const [hatcheriesAccess, setHatcheriesAccess] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [hatchery, setHatchery] = useState(null);
  const [cubiclebookingschedules, setCubiclebookingschedules] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [quarantinereport, setQuarantinereport] = useState(null);
  const [additionalbookingcharges, setAdditionalbookingcharges] = useState(null);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const dispatch = useDispatch();

  const { userRoleAccess } = useSelector(

    (state) => ({
      userRoleAccess: state.dashboardData.userRoleAccess,
    }),
    shallowEqual
  );


  useEffect(() => {
    if (userRoleAccess) {
      setModuleLevelAccessRestriction(userRoleAccess.role.permissions.application.controllers);
      setLabAnalysisAccess(userRoleAccess.role.permissions.application.controllers[`lab-analysis`].find.enabled);
      setBookingsAccess(userRoleAccess.role.permissions.application.controllers[`booking`].find.enabled);
      setCertificatesAccess(userRoleAccess.role.permissions.application.controllers[`clearance-certificate`].find.enabled);
      setUserAccountsAccess(userRoleAccess.role.permissions.application.controllers[`user-profile`].find.enabled);
      setHatcheriesAccess(userRoleAccess.role.permissions.application.controllers[`hatchery`].find.enabled);
      setUserRolesAccess(userRoleAccess.role.permissions[`users-permissions`].controllers[`user`].find.enabled);
      setReportsAccess(userRoleAccess.role.permissions.application.controllers[`shipment-packing`].find.enabled);
      setTransactions(userRoleAccess.role.permissions.application.controllers[`transactions`].find.enabled);
      setSupplier(userRoleAccess.role.permissions.application.controllers[`supplier`].find.enabled);
      setHatchery(userRoleAccess.role.permissions.application.controllers[`hatchery`].find.enabled);
      setCubiclebookingschedules(userRoleAccess.role.permissions.application.controllers[`cubicle-booking-schedules`].find.enabled);
      setInvoices(userRoleAccess.role.permissions.application.controllers[`invoices`].find.enabled);
      setQuarantinereport(userRoleAccess.role.permissions.application.controllers[`quarantine-report`].find.enabled);
      setAdditionalbookingcharges(userRoleAccess.role.permissions.application.controllers[`additional-booking-charges`].find.enabled);

    }
    else {
      return "not a user   "
    }
  });

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {hatchery &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/Hatcheries/list", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/Hatcheries/list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">Hatcheries</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Hatcheries</span>
                  </span>
                </li>
                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/hatcheries/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Registered</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/unregistered-hatcheries/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/unregistered-hatcheries/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">UnRegistered</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/expired-hatcheries/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/expired-hatcheries/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Expired</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>}


        {/*end::1 Level*/}
        {/*end::1 Level*/}
        {/* {labAnalysisAccess && 
      
       } */}
        {/*begin::1 Level*/}
        {/*end::1 Level*/}
        {bookingsAccess &&
          < li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/Bookings", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/Bookings">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">Bookings</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Bookings</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/Bookings/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/Bookings/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Present Bookings</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/past-bookings/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/past-bookings/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Past Bookings</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>}


        {/*begin::1 Level*/}
        {/*end::1 Level*/}
        {labAnalysisAccess && certificatesAccess &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/clearence-certificate", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/clearence-certificate">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">Test Reports</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Test Reports</span>
                  </span>

                </li>
                {/* begin::2 Level */}
                {/* <li
                  className={`menu-item ${getMenuItemActive(
                    "/clearence-certificate/cc"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/clearence-certificate/cc">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">View Certificates</span>
                  </NavLink>
                </li> */}
                {/* end::2 Level
           begin::2 Level */}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/clearence-certificate/cc"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/clearence-certificate/cc">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Clearence Certificate</span>
                  </NavLink>
                </li>
                {/* end::2 Level
           begin::2 Level */}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/Lab-analysis-report/lar"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/Lab-analysis-report/lar">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Lab Analysis Reports</span>
                  </NavLink>
                </li>

              </ul>
            </div>
          </li>
        }



        {/*begin::1 Level*/}
        {/*begin::1 Level*/}
        {userAccountsAccess &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/UserAccounts/Employees", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/UserAccounts/Employees">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">User Management</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">User</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/UserAccounts/Employees"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/UserAccounts/Employees">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Employees</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {hatcheriesAccess &&
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/UserAccounts/HatcheryOwners/list"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/UserAccounts/HatcheryOwners/list">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">HatcheryOwners</span>
                    </NavLink>
                  </li>
                }

              </ul>
            </div>
          </li>}


        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {cubiclebookingschedules &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/MasterCalendar/list", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/MasterCalendar/list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">Master Calendar</span>
            </NavLink>

          </li>
        }


        {additionalbookingcharges &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/Approvals/transactions", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/Approvals/transactions">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">Approvals</span>
              <i className="menu-arrow" />

            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Approvals</span>
                  </span>
                </li>

                {/*begin::2 Level*/}

                {/* <li
                  className={`menu-item ${getMenuItemActive(
                      "/Approvals/booking-changes"
                  )}`}
                  aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/Approvals/booking-changes">
                  <i className="menu-bullet menu-bullet-dot">
                    <span/>
                  </i>
                  <span className="menu-text">Booking Changes</span>
                </NavLink>
              </li> */}
                {/*end::2 Level*/}

                {/*begin::2 Level*/}

                {/* <li
                  className={`menu-item ${getMenuItemActive(
                      "/Approvals/transactions/list"
                  )}`}
                  aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/Approvals/transactions/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span/>
                  </i>
                  <span className="menu-text">Transactions Approval</span>
                </NavLink>
              </li> */}
                {/*end::2 Level*/}

                {/* <li
                  className={`menu-item ${getMenuItemActive(
                      "/Approvals/booking/list"
                  )}`}
                  aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/Approvals/booking/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span/>
                  </i>
                  <span className="menu-text">New Bookings Approval</span>
                </NavLink>
              </li> */}
                {/*end::2 Level*/}

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/Approvals/hatchery-changes/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/Approvals/hatchery-changes/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Hatchery Changes</span>
                  </NavLink>
                </li>


                <li
                  className={`menu-item ${getMenuItemActive(
                    "/Approvals/hatcheries/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/Approvals/hatcheries/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Hatcheries</span>
                  </NavLink>
                </li>
                {/* *****************/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/Approvals/hatcheries/list"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/Approvals/documents/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Shipping Documents</span>
                  </NavLink>
                </li>

              </ul>
            </div>
          </li>}




        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {supplier &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/Suppliers/list", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/Suppliers/list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">Suppliers</span>
            </NavLink>

          </li>
        }

        {additionalbookingcharges &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/additional-charges/add", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/additional-charges/add">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">Additional Charges</span>
            </NavLink>

          </li>
        }


        {/*end::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {transactions &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/payment-receipts/list", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
		  {
			  /*<NavLink className="menu-link menu-toggle" to="/payment-receipts/list">
				  <span className="svg-icon menu-icon">
					<SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
				  </span>
				  <span className="menu-text">Payment Receipts</span>
				</NavLink>*/
		  }

          </li>
        }

        {/*end::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {transactions && <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/transactions", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/transactions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
            </span>
            <span className="menu-text">Transactions</span>
          </NavLink>

        </li>}


        {userRolesAccess && <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/RoleManagement/Roles/list", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/RoleManagement/Roles/list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
            </span>
            <span className="menu-text">Roles</span>
          </NavLink>

        </li>}



        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {reportsAccess &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/Reports/list", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/Reports/list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">Reports</span>
            </NavLink>
          </li>}


        {/*end::1 Level*/}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {reportsAccess &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/reports-others/list", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/reports-others/list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/files/Folder-solid.svg")} />
              </span>
              <span className="menu-text">Test Reports</span>
            </NavLink>

          </li>
        }
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}


        {/* Components */}
        {/* begin::section */}
        {/* <li className="menu-section ">
            <h4 className="menu-text">Components</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
        {/* end:: section */}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
