import axios from "axios";
import CryptoJS from "crypto-js";


// export const LOGIN_URL = "api/auth/login";
export const LOGIN_URL = 'auth/local';
export const UPDATE = '/users';
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";
export const USER_PERMISSIONS_URL = "auth/permissions";
export const LOGOUT_URL = 'auth/logout';


export function login(email, password) {
  const identifier = email;
  
  // encrypt body params before calling API
  let formData = CryptoJS.AES.encrypt(JSON.stringify({identifier, password}), 
	process.env.REACT_APP_BODY_ENCRYPT_KEY).toString();

  return axios.post(LOGIN_URL, { formData });
}

export function setUserProfile(id, user) {

  console.log(id, user)
  return axios.put(`${UPDATE}/${id}`, { user });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getUserPermissions() {
  // Authorization head should be fulfilled in interceptor.
  console.log("requesting role permissions"); 
  return axios.get(USER_PERMISSIONS_URL);
}

export function logout(token) {
	return axios.post(LOGOUT_URL, { jwt : token });
}