import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  bookingsCount: 0,
  hatcheriesCount: 0,
  larCount: 0,
  bookingsBasedOnSpeciesType: null,
  TotalBookingsVsOpenBookings: 0,
  TotalBookingsData: null,
  totalLARVsVerified: 0,
  bookingsCC: null,
  totalLARVsVerifiedData: null,
  bookingsCountCC: 0,
  lastError: null,
  hatcheries: null,
  hatcheriesCount: 0,
  suppliers: null,
  suppliersCount: 0,
  userRoleAccess: null,
  cubicalsCount: 0,
  speciesCount: 0,
  cubiclesData: null,
  speciesData: null,
  notifications: null,
  notificationsCount: 0,
  countryData: null,
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const dashboardSlice = createSlice({
  name: "dashboardData",
  initialState: initialProductsState,

  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    fetchedUserRoleAccess: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userRoleAccess = totalCount;
    },
    cubiclesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.cubiclesData = entities;
      state.cubicalsCount = totalCount;
	},
    SpeciesDetailsFetched: (state, action) => {
      const { totalCount1, entities1 } = action.payload;
     
      state.listLoading = false;
      state.error = null;
      state.speciesData = entities1;
      state.speciesCount = totalCount1;
    },
    fetchedBooking: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bookingsCount = totalCount;
    },

    fetchCountry: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.countryData = entities;

      // state.CountryCount = totalCount;
    },
    fetchedBookingsForCC: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bookingsCC = entities;
      state.bookingsCountCC = totalCount;
    },
    fetchedHatcheries: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.hatcheries = entities;
      state.hatcheriesCount = totalCount;
    },

    fetchedSuppliers: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.suppliers = entities;
      state.suppliersCount = totalCount;
    },

    fetchedNotifications: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.notifications = entities;
      state.notificationsCount = totalCount;
    },



    fetchedLARCount: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.larCount = totalCount;
    },
    fetchedHatcheriesCount: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.hatcheriesCount = totalCount;
    },
    fetchedBookingVsSpecies: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bookingsBasedOnSpeciesType = totalCount;
    },
    fetchedTotalVsOpenBookings: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.TotalBookingsVsOpenBookings = totalCount.length;
      state.TotalBookingsData = totalCount;
    },
    fetchedTotalVsOpenLAR: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalLARVsVerified = totalCount;
      state.totalLARVsVerifiedData = entities;
    },



  }
});
