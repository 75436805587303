import axios from "axios";

export const PRODUCTS_URL = "/bookings?_start=0&&_limit=10&&_sort=id:desc";
export const AUTH_PERSON_UPDATE_URL = "/bookings/add-authorized-person";
export const SHIPPING_UPDATE_URL = "/bookings/add-shipment-details";
export const BASE_PRODUCTS_URL = "/bookings";
export const VERIFY_PAYMENT_URL = "/verify-booking-payment";


export const PAST_PRODUCTS_URL = "/bookings?_start=10&&_limit=100&&_sort=id:desc";
export const BOOKINGS = "/cubicle-booking-schedules"
export const SUPPLIERS = "/suppliers"
export const BOOKING_CANCEL_URL = "/bookings/cancel";
// CREATE =>  POST: add a new booking to the server
export function createBooking(booking) {
  return axios.post(BASE_PRODUCTS_URL, booking);
}
//?availableDate_gte=2020-12-31&availableDate_lte=2021-01-01
// READ
export function getAllBookings() {
  return axios.get(PRODUCTS_URL);
}

export function getAllSuppliers() {
  return axios.get(SUPPLIERS);
}

export function getAllPastBookings() {
  return axios.get(PAST_PRODUCTS_URL);
}

export function getBookingById(bookingId) {
  return axios.get(`${BASE_PRODUCTS_URL}/${bookingId}`);
}

export function verifyBookingPayment(queryParams) {
  return axios.post(`${VERIFY_PAYMENT_URL}`, queryParams);
}

export function findBookingByMonth(queryParams, speciesType) {
  console.log(queryParams)
  return axios.get(`${BOOKINGS}?availableDate_gte=${queryParams.start}&availableDate_lte=${queryParams.end}&speciesId=${speciesType}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBookings(queryParams) {
  return axios.post(`${BASE_PRODUCTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateBooking(booking) {
  return axios.put(`${BASE_PRODUCTS_URL}/${booking.id}`, booking);
}
export function updateAuthPerson(authorisedPerson) {
  return axios.put(`${AUTH_PERSON_UPDATE_URL}/${authorisedPerson.id}`, { authorisedPerson });
}
export function updateShippingDetails(shipmentDetail) {
  return axios.put(`${SHIPPING_UPDATE_URL}/${shipmentDetail.id}`, shipmentDetail);
}

// UPDATE Status
export function updateStatusForBookings(ids, status) {
  return axios.post(`${BASE_PRODUCTS_URL}/updateStatusForBookings`, {
    ids,
    status
  });
}

// DELETE => delete the booking from the server
export function deleteBooking(bookingId, cancelRemark) {
  return axios.put(`${BOOKING_CANCEL_URL}/${bookingId}`, { cancelRemark });
}

// DELETE Bookings by ids
export function deleteBookings(ids) {
  return axios.post(`${BASE_PRODUCTS_URL}/deleteBookings`, { ids });
}
