import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./BookingsUIHelpers";

const BookingsUIContext = createContext();

export function useBookingsUIContext() {
  return useContext(BookingsUIContext);
}

export const BookingsUIConsumer = BookingsUIContext.Consumer;

export function BookingsUIProvider({ bookingsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newBookingButtonClick: bookingsUIEvents.newBookingButtonClick,
    openEditBookingPage: bookingsUIEvents.openEditBookingPage,
    openViewBookingPage: bookingsUIEvents.openViewBookingPage,
    openDeleteBookingDialog: bookingsUIEvents.openDeleteBookingDialog,
    openDeleteBookingsDialog: bookingsUIEvents.openDeleteBookingsDialog,
    openFetchBookingsDialog: bookingsUIEvents.openFetchBookingsDialog,
    openUpdateBookingsStatusDialog:
      bookingsUIEvents.openUpdateBookingsStatusDialog,
  };

  return (
    <BookingsUIContext.Provider value={value}>
      {children}
    </BookingsUIContext.Provider>
  );
}
