// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/bookings/bookingsActions";
import * as uiHelpers from "../BookingsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_websoc/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_websoc/_partials/controls";
import { useBookingsUIContext } from "../BookingsUIContext";

export function BookingsTable() {
  // Bookings UI Context
  const bookingsUIContext = useBookingsUIContext();
  const bookingsUIProps = useMemo(() => {
    return {
      ids: bookingsUIContext.ids,
      setIds: bookingsUIContext.setIds,
      queryParams: bookingsUIContext.queryParams,
      setQueryParams: bookingsUIContext.setQueryParams,
      openEditBookingPage: bookingsUIContext.openEditBookingPage,
      openViewBookingPage: bookingsUIContext.openViewBookingPage,
      openDeleteBookingDialog: bookingsUIContext.openDeleteBookingDialog,
    };
  }, [bookingsUIContext]);

  // Getting curret state of bookings list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.bookings }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Bookings Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    bookingsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchBookings(bookingsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "Booking ID",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "hatcheryId.name",
      text: "Hatchery Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "toDate",
      text: "Booking Date",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "speciesType",
      text: "Species Type",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "totalBioMass",
      text: "Total Biomass",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "totalPrice",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "modelYear",
    //   text: "Model Year",
    //   sort: true,
    //   sortCaret: sortCaret,
    // },
    // {
    //   dataField: "color",
    //   text: "Color",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.ColorColumnFormatter,
    // },
    {
      dataField: "documents.nocCertificate",
      text: "NOC",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PriceColumnFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "condition",
    //   text: "Condition",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.ConditionColumnFormatter,
    // },
    // {
    //   dataField: "documents",
    //   text: "NOC",
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     // openEditBookingPage: bookingsUIProps.openEditBookingPage,
    //     // openDeleteBookingDialog: bookingsUIProps.openDeleteBookingDialog,
    //     openViewBookingPage: bookingsUIProps.openViewBookingPage,
    //   },

    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        // openEditBookingPage: bookingsUIProps.openEditBookingPage,
        // openDeleteBookingDialog: bookingsUIProps.openDeleteBookingDialog,
        openViewBookingPage: bookingsUIProps.openViewBookingPage,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "50px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: bookingsUIProps.queryParams.pageSize,
    page: bookingsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        keyField="id"
        data={entities === null ? [] : entities}
        columns={columns}
        defaultSorted={uiHelpers.defaultSorted}

      ></BootstrapTable>
    </>
  );
}
