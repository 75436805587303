import * as requestFromServer from "./bookingsCrud";
import {bookingsSlice, callTypes} from "./bookingsSlice";
import swal from 'sweetalert';

const {actions} = bookingsSlice;

export const fetchBookings = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllBookings(queryParams)
    .then(response => {
      const  entities = response.data;
      const  totalCount = response.data.length;
      dispatch(actions.bookingsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bookings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPastBookings = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllPastBookings(queryParams)
    .then(response => {
      const  entities = response.data;
      const  totalCount = response.data.length;
      dispatch(actions.pastBookingsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find bookings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBooking = id => dispatch => {
  if (!id) {
    return dispatch(actions.bookingFetched({ bookingForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBookingById(id)
    .then(response => {
      const booking = response.data;
      dispatch(actions.bookingFetched({ bookingForEdit: booking }));
    })
    .catch(error => {
      error.clientMessage = "Can't find booking";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBooking = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBooking(id)
    .then(response => {
      swal("Booking Deleted!", "success");
      dispatch(actions.bookingDeleted({ id }));
    })
    .catch(error => {
      if(error.response)
      swal(error.response.data.message,"Booking Not Deleted", "error");
      error.clientMessage = "Can't delete booking";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createBooking = bookingForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBooking(bookingForCreation)
    .then(response => {
      swal("Booking Created!", "success");
      const { booking } = response.data;
      dispatch(actions.bookingCreated({ booking }));
    })
    .catch(error => {
      if(error.response)
      swal(error.response.data.message,"Booking Not Created", "error");
      error.clientMessage = "Can't create booking";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBooking = booking => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBooking(booking)
    .then(() => {
      swal("Booking Updated!", "success");
      dispatch(actions.bookingUpdated({ booking }));
    })
    .catch(error => {
      if(error.response)
      swal(error.response.data.message,"Booking Not Updated", "error");
      error.clientMessage = "Can't update booking";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBookingsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForBookings(ids, status)
    .then(() => {
      dispatch(actions.bookingsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update bookings status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBookings = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBookings(ids)
    .then(() => {
      dispatch(actions.bookingsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete bookings";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
